<div class="container-cards">
  <div>
    <div class="ctn-card bg-white">
      <div class="ctn-card-header">
        <h3 class="px-0 pb-1 pt-0 text-start">
          {{ product.numberContributions }} cuotas
        </h3>
      </div>
      <div>
        <h3 class="p-0 my-2 text-start">{{ product.savingsPlan.name }}</h3>
        <div class="p-0">
          <div class="mb-3 mt-2">
            <h1 class="save-text">
              Ahorro mensual<br />
              <span class="pricetotal"
                >${{
                  product.contributionPrice
                    | currency : "COP" : "" : "1.0-0" : "es-CO"
                }}
                <span class="fw-normal">COP</span>
              </span>
            </h1>
          </div>
          <div class="row mt-3">
            <div class="col">
              <h1 class="save-text">Ahorro estimado total</h1>
              <h2 class="price">
                ${{
                  product.totalPrice | currency : "COP" : "" : "1.0-0" : "es-CO"
                }}
                COP
              </h2>
            </div>
            <div class="col">
              <h1 class="save-text">Ahorro diario</h1>
              <h2 class="price">
                ${{
                  product.dailyPrice | currency : "COP" : "" : "1.0-0" : "es-CO"
                }}
                COP
              </h2>
            </div>
          </div>
          <div class="my-3 my-md-4">
            <h1
              class="text-table-deaits"
              style="text-align: left; font-weight: 600 !important"
            >
              Beneficios adicionales
            </h1>
          </div>
          <div class="d-flex">
            <img class="icn-check" src="\assets\icons\check.svg" />
            <h1 class="text-benefits">
              Sorteo mensual en el cual puedes <br />
              ganar el valor total que proyectas <br />
              ahorrar.
            </h1>
          </div>
          <div class="d-flex">
            <img class="icn-check" src="\assets\icons\check.svg" />
            <h1 class="text-benefits">Noches de hospedaje. <br /></h1>
          </div>
          <div class="d-flex">
            <img class="icn-check" src="\assets\icons\check.svg" />
            <h1 class="text-benefits">
              Puntos para redimir en comercios <br />
              aliados. <br />
            </h1>
          </div>
          <div class="d-flex text-break w-full">
            <span class="title-back text-start mt-4 text-break w-full">
              Los beneficios dependen del plan y valor de aporte <br />
              seleccionado. Las noches de hospedaje y puntos para redimir<br />
              en comercios, aplican exclusivamente para contratos a 24 y 36<br />
              aportes con cuota mensual igual o superior a $200.000.<br />
              Aplican condiciones y restricciones.
            </span>
          </div>
          <div class="d-flex justify-content-center pb-3">
            <a
              style="font-weight: bold"
              href="https://www.cvu.com.co/pago/clausula-contrato"
              target="_blank"
              >Conoce el clausulado</a
            >
          </div>
          <div class="d-flex">
            <button (click)="initPlan()" class="btn-init">Iniciar plan</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
