import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import {
  TravelPlanState,
  TravelPlanSummaryModel,
} from 'src/domain/models/travel-plan.model';
import { environment } from 'src/environments/environment';
import { ResponseData } from 'src/base/wrappers/response-data';
import { PlanContractEntity } from './entities/plan-contract.entity';
import { PlanContractMapper } from './mappers/plan-contract-summary.mapper';
import { PlanContractRepository } from 'src/domain/repositories/plan-contract.repository';
import { PlanContractModel } from 'src/domain/models/plan-contract/plan-contract.model';
import { PaginatedData } from 'src/base/wrappers/paginated-data';
import { PlanContractShortModel } from 'src/domain/models/plan-contract/plan-contract-short.model';
import { PlanContractShortEntity } from './entities/plan-contract-short.entity';
import { ShortPlanContractMapper } from './mappers/short-plan-contract.mapper';
import { PaymentSummaryEntity } from '../travel-plan/entities/payment-summary.entity';
import { PaymentSummaryModel } from 'src/domain/models/payment-summary.model';
import { PaymentSummaryMapper } from '../travel-plan/mappers/payment-summary.mapper';
import { ContributionModel } from 'src/domain/models/contribution.model';
import { ContributionEntity } from '../travel-plan/entities/contribution.entity';
import { ContributionMapper } from '../travel-plan/mappers/contribution.mapper';
import { PlanContractSummaryEntity } from './entities/plan-contract-summary.entity';
import { PlanContractSummaryMapper } from './mappers/travel-plan-summary.mapper';
import { TransactionModel } from 'src/domain/models/transaction.model';
import { TransactionEntity } from './entities/transaction.entity';
import { TransactionMapper } from './mappers/transaction.mapper';
import { AvailableContractModel } from 'src/domain/models/available-contract.model';
import { AvailableContractEntity } from './entities/available-contract.entity';
import { AvailableContractForSubscriptionMapper } from './mappers/available-contract.mapper';

@Injectable({
  providedIn: 'root',
})
export class PlanContractImplRepository extends PlanContractRepository {
  private readonly planContractUrl: string;
  private travelPlanMapper = new PlanContractMapper();
  private shortPlanContractMapper = new ShortPlanContractMapper();
  private paymentSummaryMapper = new PaymentSummaryMapper();
  private contributionMapper = new ContributionMapper();
  private planContractSummaryMapper = new PlanContractSummaryMapper();
  private transactionMapper = new TransactionMapper();
  private availableContractMapper =
    new AvailableContractForSubscriptionMapper();

  constructor(private http: HttpClient) {
    super();
    this.planContractUrl = `${environment.uriBase}PlanContract`;
  }

  override getPlan(id: string): Observable<PlanContractModel> {
    return this.http
      .get<ResponseData<PlanContractEntity>>(`${this.planContractUrl}/${id}`)
      .pipe(map((result) => this.travelPlanMapper.mapFrom(result.data)));
  }

  override getPlans(
    state: TravelPlanState,
    pageNumber: number,
    pageSize: number
  ): Observable<PaginatedData<PlanContractShortModel>> {
    let params = new HttpParams();

    params = params.set('pageNumber', pageNumber.toString());
    params = params.set('pageSize', pageSize.toString());

    return this.http
      .get<ResponseData<PaginatedData<PlanContractShortEntity>>>(
        `${this.planContractUrl}/state/${state}`,
        { params }
      )
      .pipe(
        map((result) => ({
          ...result.data,
          data: (result.data.data ?? []).map(
            this.shortPlanContractMapper.mapFrom
          ),
        })),
        catchError((error: HttpErrorResponse) => {
          console.error(error);
            if(error.status===409 && error.error.code===1003 ){
              return throwError(
                () =>
                  'updateUser'
              );
            }
          return throwError(
            () =>
              ''
          );
        })
      );
  }

  override getPaymentSummary(planId: string): Observable<PaymentSummaryEntity> {
    return this.http
      .get<ResponseData<PaymentSummaryModel>>(
        `${this.planContractUrl}/${planId}/Transaction/contribution/next`
      )
      .pipe(map((result) => this.paymentSummaryMapper.mapFrom(result.data)));
  }

  override postTransactionContribution(
    contribution: ContributionModel,
    planId: string
  ): Observable<ContributionModel> {
    return this.http
      .post<ResponseData<ContributionEntity>>(
        `${this.planContractUrl}/${planId}/Transaction/contribution`,
        this.contributionMapper.mapTo(contribution)
      )
      .pipe(
        map((result) => {
          return this.contributionMapper.mapFrom(result.data);
        })
      );
  }

  override getPlansSummary(
    state: TravelPlanState,
    pageNumber: number,
    pageSize: number
  ): Observable<PaginatedData<TravelPlanSummaryModel>> {
    let params = new HttpParams();

    params = params.set('pageNumber', pageNumber.toString());
    params = params.set('pageSize', pageSize.toString());
    
    return this.http
      .get<ResponseData<PaginatedData<PlanContractSummaryEntity>>>(
        `${this.planContractUrl}/state/${state}/summary`,
        { params }
      )
      .pipe(
        map((result) => {
          return {
            ...result.data,

            data: (result.data.data ?? []).map(
              this.planContractSummaryMapper.mapFrom
            ),
          };
        }),
        catchError((error: HttpErrorResponse) => {
          console.error(error);
            if(error.status===409 && error.error.code===1003 ){
              return throwError(
                () =>
                  'updateUser'
              );
            }
          return throwError(
            () =>
              ''
          );
        })
      );
  }

  override getTransactionsList(
    planId: string
  ): Observable<PaginatedData<TransactionModel>> {
    return this.http
      .get<ResponseData<PaginatedData<TransactionEntity>>>(
        `${this.planContractUrl}/${planId}/Transaction`
      )
      .pipe(
        map((result) => ({
          ...result.data,
          data: (result.data.data ?? []).map(this.transactionMapper.mapFrom),
        }))
      );
  }

  override getAvailableContractForSubscription(): Observable<
    PaginatedData<AvailableContractModel>
  > {
    return this.http
      .get<ResponseData<PaginatedData<AvailableContractEntity>>>(
        `${this.planContractUrl}/cvu-payment/available-for-subscription`
      )
      .pipe(
        map((result) => ({
          ...result.data,
          data: (result.data.data ?? []).map(
            this.availableContractMapper.mapFrom
          ),
        }))
      );
  }

  override getAvailableContractForContribution(
    idPlan: string
  ): Observable<PaginatedData<AvailableContractModel>> {
    return this.http
      .get<ResponseData<PaginatedData<AvailableContractEntity>>>(
        `${this.planContractUrl}/${idPlan}/cvu-payment/available-for-contribution`
      )
      .pipe(
        map((result) => ({
          ...result.data,
          data: (result.data.data ?? []).map(
            this.availableContractMapper.mapFrom
          ),
        }))
      );
  }
}
