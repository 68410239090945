import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { TravelPlanRepository } from 'src/domain/repositories/travel-plan.repository';
import { ShortTravelPlanMapper } from './mappers/short-travel-plan.mapper';
import {
  TravelPlanModel,
  TravelPlanShortModel,
  TravelPlanState,
  TravelPlanSummaryModel,
} from 'src/domain/models/travel-plan.model';
import { TravelPlanEntity } from './entities/travel-plan-entity';
import { environment } from 'src/environments/environment';
import { PaginatedData } from 'src/base/wrappers/paginated-data';
import { ResponseData } from 'src/base/wrappers/response-data';
import { TravelPlanMapper } from './mappers/travel-plan.mapper';
import { TravelPlanSummaryEntity } from './entities/travel-plan-summary.entity';
import { TravelPlanSummaryMapper } from './mappers/travel-plan-summary.mapper';

@Injectable({
  providedIn: 'root',
})
export class TravelPlanImplRepository extends TravelPlanRepository {
  private readonly travelPlanUrl: string;
  private shortTravelMapper = new ShortTravelPlanMapper();
  private travelPlanMapper = new TravelPlanMapper();
  private travelPlanSummaryMapper = new TravelPlanSummaryMapper();
  private travelPlanSummary = new TravelPlanSummaryMapper();

  constructor(private http: HttpClient) {
    super();
    this.travelPlanUrl = `${environment.uriBase}TravelPlan`;
  }

  override getPlan(id: string): Observable<TravelPlanModel> {
    return this.http
      .get<ResponseData<TravelPlanEntity>>(`${this.travelPlanUrl}/${id}`)
      .pipe(map((result) => this.travelPlanMapper.mapFrom(result.data)));
  }

  override getPlans(
    state: TravelPlanState,
    pageNumber: number,
    pageSize: number
  ): Observable<PaginatedData<TravelPlanShortModel>> {
    let params = new HttpParams();

    params = params.set('pageNumber', pageNumber.toString());
    params = params.set('pageSize', pageSize.toString());

    return this.http
      .get<ResponseData<PaginatedData<TravelPlanEntity>>>(
        `${this.travelPlanUrl}/${state}`,
        { params }
      )
      .pipe(
        map((result) => ({
          ...result.data,
          data: (result.data.data ?? []).map(this.shortTravelMapper.mapFrom),
        }))
      );
  }

  override getPlansSummary(
    state: TravelPlanState,
    pageNumber: number,
    pageSize: number
  ): Observable<PaginatedData<TravelPlanSummaryModel>> {
    let params = new HttpParams();

    params = params.set('pageNumber', pageNumber.toString());
    params = params.set('pageSize', pageSize.toString());

    return this.http
      .get<ResponseData<PaginatedData<TravelPlanSummaryEntity>>>(
        `${this.travelPlanUrl}/${state}/summary`,
        { params }
      )
      .pipe(
        map((result) => ({
          ...result.data,
          data: (result.data.data ?? []).map(
            this.travelPlanSummaryMapper.mapFrom
          ),
        }))
      );
  }

  override getPlanSummary(planId: string): Observable<TravelPlanSummaryModel> {
    return this.http
      .get<ResponseData<TravelPlanSummaryEntity>>(
        `${this.travelPlanUrl}/${planId}/summary`
      )
      .pipe(map((result) => this.travelPlanSummary.mapFrom(result.data)));
  }

  override deletePlan(id: string): Observable<{}> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {        
      },
    };
    return this.http.delete(`${this.travelPlanUrl}/${id}`, options);
  }

  override patchEditPlan(
    planId: string,
    numberContributions: number,
    monthlyContributionPrice: number
  ): Observable<TravelPlanModel> {
    const url = `${this.travelPlanUrl}/${planId}`;

    const updatedFields = {
      numberContributions,
      monthlyContributionPrice,
    };

    return this.http
      .patch<ResponseData<TravelPlanEntity>>(url, updatedFields)
      .pipe(map((result) => this.travelPlanMapper.mapFrom(result.data)));
  }

  override signContract(idPlan: string): Observable<TravelPlanModel> {
    return this.http
      .post<ResponseData<TravelPlanEntity>>(
        `${this.travelPlanUrl}/${idPlan}/subscribe/sign-contract/start-signature`,
        { idPlan }
      )
      .pipe(
        map((result) => {
          return this.travelPlanMapper.mapFrom(result.data);
        })
      );
  }
}
