<footer class="footer">
  <img class="img-bg" [src]="'/assets/backgrounds/footer/'+ waveImageUrl" alt="Decoración">
  <div class="section" *ngIf="!isResumed">
      <div class="container-news">
          <div class="news-letter">
              <img src="\assets\logos\Logo-email.png" class="logo-footer" alt="Logo CVU">
              <div class="news-input">
                  <p style="margin-top: 20px; cursor: default;">
                      Únete a nuestro boletín informativo para recibir noticias y promociones
                  </p>
                  <div class="news-actions">
                      <input type="email" placeholder="Ingresa tu correo electrónico" style="margin-right: 10px;">
                      <button class="btn-input" [disabled]="true">Suscríbete</button>
                  </div>
                  <p class="poli-text" style="margin-top: 20px; cursor: default;">
                      Al suscribirte, aceptas nuestra <a class="text-redirect" style="font-size:12px;" href="https://www.cvu.com.co/aviso-de-privacidad">Política de privacidad</a> y brindas tu consentimiento para recibir
                      actualizaciones de nuestra empresa.
                  </p>
              </div>
          </div>
          <div class="links">
              <div class="col links-column">
                  <h2>Nosotros</h2>
                  <a href="https://www.cvu.com.co/blog-vamos">Blog Vamos</a>
                  <a href="https://www.cvu.com.co/politica-sagrilaft">Política SAGRILAFT</a>
                  <a href="https://www.cvu.com.co/documents/20126/0/Certificacion-de-Implementacion-del-SAGRILAFT-el-PTEE-2023-CVU.pdf/f5c1ce6c-4b8f-b222-b889-4d36f223a73a?t=1689090476922">Informe Oficial de Cumplimiento</a>
                  <a routerLink="auth/register">Registro</a>
                  <a href="https://www.cvu.com.co/aviso-de-privacidad">Aviso de privacidad</a>
                  <a href="https://www.cvu.com.co/documents/20126/0/POL%C3%8DTICA+DE+TRATAMIENTO+Y+PROTECCI%C3%93N+DE+DATOS+PERSONALES+CVU.pdf/83cd6c6c-7bc8-84c5-e02a-a3f277ef0307?t=1619626770539">Política de tratamiento y protección de datos personales</a>
              </div>
              <div class="col">
                  <h2>Nuestros usuarios</h2>
                  <a href="https://www.cvu.com.co/planes-de-ahorro">Planes de Ahorro</a>
                  <a href="https://www.cvu.com.co/ganadores">Sorteo</a>
                  <a href="https://www.sic.gov.co/">Superintendencia de Industria y Comercio</a>
                  <a href="https://www.supertransporte.gov.co/">Superintendencia de Transporte</a>
                  <a href="https://www.cvu.com.co/aliados-prestadores-turisticos">Aliados Prestadores Turísticos</a>
                  <a href="https://www.cvu.com.co/derechos-y-obligaciones-de-los-ahorradores">Derechos y obligaciones de los ahorradores</a>
                  <a href="https://www.cvu.com.co/recargate-de-viajes-ventas">Recárgate de viajes</a>
                  <a href="https://ayuda.cvu.com.co/portal/es/home">Servicio al cliente</a>
              </div>
          </div>
      </div>
      <div class="container-rights">
          <div class="col">
              <h2>Síguenos</h2>
              <div class="social">
                  <a href="https://www.facebook.com/CVU.Colombia" target="_blank"><i class="fab fa-facebook"></i>Facebook</a>
                  <a href="https://www.instagram.com/cvucol/" target="_blank"><i class="fab fa-instagram"></i>Instagram</a>
              </div>
          </div>
          <div class="col" style="margin-top: 20px;">
              <p class="rights">
                  Estamos facultados para operar por la Ley 300 de 1996 y demás normas que la adicionen o modifiquen. Actuamos
                  bajo la inspección, vigilancia y control del Ministerio de Comercio, Industria y Turismo, la Superintendencia de
                  Sociedades, la Superintendencia de Industria y Comercio.
              </p>
              <p class="rights">
                  Registro Nacional de Turismo Vigente: 401, 391, 404, 402, 405, 25254, 5064, 24839, 15958, 408, 28190, 24814,
                  26205, 393, 394, 30032, 12874.
              </p>
              <p class="rights">
                  Para notificaciones judiciales escribanos a:  <a class="text-redirect" href="mailto:notificacionjudicial@cvu.com.co">notificacionjudicial@cvu.com.co</a>
              </p>
              <p class="rights">
                  Si necesitas contactarnos para protección de datos o ley de habeas data, escríbenos al
                  correo: <a class="text-redirect" href="mailto:datosclientes@cvu.com.co">datosclientes@cvu.com.co</a>
              </p>
          </div>
      </div>
      <div class="container-credits">
          <div class="credit-conditions">
              <p class="conditions" style="cursor: default;">© Círculo de Viajes Universal. Todos los derechos reservados 2019 | <a class="text-redirect" href="https://www.cvu.com.co/simulador-seleccion-plan/terminos-y-condiciones">Términos y Condiciones del sitio.</a></p>
          </div>
          <div class="credit-links">
              <a class="me-2 pe-1" href="https://www.cvu.com.co/aviso-de-privacidad">Política de privacidad</a>
              <a href="https://www.cvu.com.co/simulador-seleccion-plan/terminos-y-condiciones">Términos de servicio</a>
          </div>
      </div>
  </div>
  <div class="section-resume" *ngIf="isResumed">
      <div class="resume-content">
          <img src="/assets/logos/Logo-email.png" class="logo-resume-footer" alt="Logo CVU">
          <p class="conditions">
              © Círculo de Viajes Universal. Todos los derechos reservados 2019 |
              <a class="text-redirect" href="https://www.cvu.com.co/simulador-seleccion-plan/terminos-y-condiciones">
                  Términos y Condiciones del sitio.
              </a>
          </p>
          <div class="credit-links">
              <a href="https://www.cvu.com.co/aviso-de-privacidad">Política de privacidad</a>
              <a href="https://www.cvu.com.co/simulador-seleccion-plan/terminos-y-condiciones">Términos de servicio</a>
          </div>
      </div>
  </div>
</footer>
