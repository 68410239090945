export const AUTHENTICATION_DATA_KEY = 'auth';
export const AUTHENTICATION_USER_ID = 'key';
export const AUTHENTICATION_DEVICE = 'device';
export const OPENPAY_TRANSACTION = 'transaction';
export const OPENPAY_RADICATENUM = 'radicatedNum';
export const OPENPAY_TRAVEL_PLAN = 'travel_plan';
export const OPENPAY_CONTRIBUTION = 'contribution';
export const OPENPAY_SUBSCRIPTION = 'subscriptionInfo';
export const OPENPAY_TRAVEL_PLAN_DATA = 'travelPlanData';
export const OPENPAY_PAYMENT_SUMMARY = 'paymentSummary';
export const OPENPAY_PAYMENT_PAY_CONTRIBUTION = 'payContributionV';
